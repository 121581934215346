<template>
	<div>
		<supplierPasswordComponent />
	</div>
</template>
<script>
import supplierPasswordComponent from '@/components/system/Auth/SupplierPassword'

export default {
	components: {
		supplierPasswordComponent,
	},
}
</script>
