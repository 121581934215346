<template>
	<div>
		<div class="text-center" style="padding: 10px 0;">
			<img src="resources/images/Logo-Origami.png" width="80%" />
		</div>
		<a-spin :tip="loadingLabel" size="large" :spinning="loading">
			<div class="card" :class="$style.container">
				<div class="text-dark font-size-24 mb-3">
					<strong>Contraseña de acceso a sistema</strong>
				</div>
				<a-form class="mb-4" :form="form" @submit="handleSubmit">
					<a-form-item label="Contraseña">
						<a-input-password placeholder="Ingresa nueva contraseña" v-decorator="[
								'password',
								{
									rules: [
										{ required: true, message: 'Campo requerido.' },
										{
											min: 6,
											message: 'Ingrese contraseña de al menos 6 caracteres.',
										},
										{
											validator: validateToNextPassword,
										},
									],
								},
							]" autocomplete="off" />
					</a-form-item>
					<a-form-item label="Confirma tu contraseña">
						<a-input-password placeholder="Confirma tu nueva contraseña" @blur="handleConfirmBlur" v-decorator="[
								'confirmPassword',
								{
									rules: [
										{ required: true, message: 'Campo requerido.' },
										{
											min: 6,
											message: 'Ingrese contraseña de al menos 6 caracteres.',
										},
										{
											validator: compareToFirstPassword,
										},
									],
								},
							]" autocomplete="off" />
					</a-form-item>
					<a-button type="primary" htmlType="submit" size="large" class="text-center w-100" :loading="loading">
						<strong>Establecer contraseña de acceso</strong>
					</a-button>
				</a-form>
			</div>
		</a-spin>
	</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import utilities from '@/services/utilities'

export default {
	name: 'RecoveryComponent',
	computed: {
		...mapState(['settings']),
		...mapGetters('auth', ['loading', 'loadingLabel']),
		hasToken() {
			return utilities.objectValidate(this.$router.currentRoute.query, 'token', false) ? this.$router.currentRoute.query.token : false
		},
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
			confirmDirty: false,
		}
	},
	mounted() {
		//
	},
	methods: {
		// Personal Information
		handleConfirmBlur(e) {
			const value = e.target.value
			this.confirmDirty = this.confirmDirty || !!value
		},
		compareToFirstPassword(rule, value, callback) {
			if (value && value !== this.form.getFieldValue('password')) {
				callback('Las contraseñas deben coincidir.')
			} else {
				callback()
			}
		},
		validateToNextPassword(rule, value, callback) {
			if (value && this.confirmDirty) {
				this.form.validateFields(['confirmPassword'], { force: true })
			}
			callback()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					let payload = {
						...values,
						permalink: this.$router.currentRoute.params.token,
					}
					this.$store.dispatch('suppliers/SET_NEW_PASSWORD', payload)
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import '@/components/system/Auth/style.module.scss';
</style>